import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Trans } from '@lingui/macro';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';

import { formatDate, formatTime } from 'utils/formatDate';
import PageHeader from 'components/PageHeader';
import Loading from 'components/Loading';
import Error from 'components/Error';
import EmptyState from 'components/EmptyState';
import PaginatedTable from 'components/PaginatedTable';
import Modal from 'components/Modal';

const QuizNations = () => {
  const { token: urlToken } = useParams();
  const [error, setError] = useState(false);
  const [games, setGames] = useState();
  const [gameToSchedule, setGameToSchedule] = useState();
  const [scheduling, setScheduling] = useState(false);
  const [token, setToken] = useState();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_QUIZ_NATIONS_URL}games`, {
        headers: {
          Authorization: '',
        },
      })
      .then(({ data }) => {
        setGames(
          data.sort((a, b) => {
            if (!a.datetime) {
              return -1;
            }
            if (!b.datetime) {
              return 1;
            }
            return new Date(b.datetime) - new Date(a.datetime);
          })
        );
      })
      .catch(({ response }) => {
        setError(response?.status || 500);
      });
  }, []);

  const scheduleGame = useCallback(() => {
    setScheduling(true);
    axios
      .put(
        `${process.env.REACT_APP_QUIZ_NATIONS_URL}games/${gameToSchedule.id}`,
        {
          datetime: gameToSchedule.datetime,
          host_name: gameToSchedule.host_name,
          host_email: gameToSchedule.host_email,
          token,
        },
        {
          headers: {
            Authorization: '',
          },
        }
      )
      .then(({ data }) => {
        setGames((prev) =>
          prev.map((game) => {
            if (game.id === data.id) {
              return data;
            }
            return game;
          })
        );
        setGameToSchedule();
        toast.success(<Trans>Jogo agendado com sucesso.</Trans>);
      })
      .catch(({ response }) => {
        if (response?.status === 403) {
          toast.error(<Trans>A palavra-passe inserida não é válida.</Trans>);
        } else if (
          response?.status === 400 &&
          response?.data?.data?.errors?.host_email
        ) {
          toast.error(<Trans>O e-mail inserido não é válido.</Trans>);
        } else {
          toast.error(<Trans>Não foi possível agendar o jogo.</Trans>);
        }
      })
      .finally(() => {
        setScheduling(false);
      });
  }, [gameToSchedule, token]);

  const gameToScheduleOldValue = useMemo(() => {
    if (gameToSchedule) {
      return games.find((item) => gameToSchedule.id === item.id);
    }
    return null;
  }, [gameToSchedule, games]);

  if (error) {
    return <Error status={error} />;
  }

  return (
    <>
      <PageHeader
        title={<Trans>Quiz Nations</Trans>}
        subtitle={
          <a
            href="https://results.quiznations.com/porxii"
            rel="noreferrer"
            target="_blank"
          >
            Portugal XII
          </a>
        }
      />
      <div className="section">
        {!games ? (
          <Loading />
        ) : (
          <>
            {games.length > 0 ? (
              <PaginatedTable
                array={games}
                itemsPerPage={12}
                columns={[
                  {
                    id: 'round',
                    className: 'is-vertical-middle',
                    label: <Trans>Jornada</Trans>,
                    render: (item) => item.round,
                  },
                  {
                    id: 'teams',
                    className: 'is-vertical-middle is-nowrap',
                    label: <Trans>Equipas</Trans>,
                    render: (item) => (
                      <>{item.teams.map((team) => team.name).join(' - ')}</>
                    ),
                  },
                  {
                    id: 'date',
                    className: 'is-vertical-middle is-nowrap',
                    label: <Trans>Data</Trans>,
                    render: (item) =>
                      item.datetime ? formatDate(new Date(item.datetime)) : '-',
                  },
                  {
                    id: 'time',
                    className: 'is-vertical-middle is-nowrap',
                    label: <Trans>Hora</Trans>,
                    render: (item) =>
                      item.datetime ? formatTime(new Date(item.datetime)) : '-',
                  },
                  {
                    id: 'host',
                    className: 'is-vertical-middle is-nowrap',
                    label: <Trans>Apresentador</Trans>,
                    render: (item) => item.host_name || '-',
                  },
                  {
                    id: 'actions',
                    label: <Trans>Agendar</Trans>,
                    render: (item) => (
                      <button
                        className="button"
                        type="button"
                        onClick={() => {
                          setToken(urlToken);
                          setGameToSchedule(item);
                        }}
                      >
                        <span className="icon">
                          <i className="fa-fw fa-solid fa-calendar-day" />
                        </span>
                      </button>
                    ),
                  },
                ]}
              />
            ) : (
              <EmptyState>
                <Trans>Sem registos</Trans>
              </EmptyState>
            )}
          </>
        )}
        {gameToSchedule && (
          <Modal
            type="info"
            open
            actionDisabled={!token || !gameToSchedule.datetime}
            title={<Trans>Agendar jogo</Trans>}
            body={
              <>
                <div className="field">
                  <div className="control">
                    <label className="label">
                      <Trans>Data do quiz</Trans>
                    </label>
                    <div className="control has-icons-left">
                      <DatePicker
                        minDate={new Date()}
                        showTimeSelect
                        timeIntervals={15}
                        selected={
                          gameToSchedule.datetime &&
                          new Date(gameToSchedule.datetime)
                        }
                        onChange={(date) => {
                          setGameToSchedule({
                            ...gameToSchedule,
                            datetime: date,
                          });
                        }}
                        dateFormat="yyyy-MM-dd HH:mm"
                      />
                      <span className="icon is-small is-left">
                        <i className="fa-fw fa-solid fa-calendar-day" />
                      </span>
                    </div>
                  </div>
                </div>
                <div className="field">
                  <label className="label">
                    <Trans>Palavra-passe</Trans>
                  </label>
                  <div className="control has-icons-left">
                    <input
                      type="text"
                      disabled={urlToken}
                      required
                      maxLength={255}
                      className="input"
                      defaultValue={token}
                      onChange={(event) => {
                        setToken(event.target.value);
                      }}
                    />
                    <span className="icon is-small is-left">
                      <i className="fa-fw fa-solid fa-key" />
                    </span>
                  </div>
                </div>
                <fieldset className="fieldset">
                  <div className="field">
                    <label className="label">
                      <Trans>Nome do apresentador</Trans>
                    </label>
                    <div className="control has-icons-left">
                      <input
                        type="name"
                        maxLength={255}
                        className="input"
                        defaultValue={gameToSchedule.host_name}
                        onChange={(event) => {
                          setGameToSchedule({
                            ...gameToSchedule,
                            host_name: event.target.value,
                          });
                        }}
                      />
                      <span className="icon is-small is-left">
                        <i className="fa-fw fa-solid fa-user" />
                      </span>
                    </div>
                  </div>
                  <div className="field">
                    <label className="label">
                      <Trans>E-mail do apresentador</Trans>{' '}
                      {gameToScheduleOldValue.datetime && (
                        <>
                          (
                          <Trans>
                            deixa em branco caso não queiras alterar
                          </Trans>
                          )
                        </>
                      )}
                    </label>
                    <div className="control has-icons-left">
                      <input
                        type="email"
                        maxLength={255}
                        className="input"
                        defaultValue={gameToSchedule.host_email}
                        onChange={(event) => {
                          setGameToSchedule({
                            ...gameToSchedule,
                            host_email: event.target.value,
                          });
                        }}
                      />
                      <span className="icon is-small is-left">
                        <i className="fa-fw fa-solid fa-at" />
                      </span>
                    </div>
                  </div>
                </fieldset>
              </>
            }
            action={() => {
              scheduleGame();
            }}
            doingAction={scheduling}
            onClose={() => setGameToSchedule()}
          />
        )}
      </div>
    </>
  );
};

export default QuizNations;
